export async function getOverviewLevel3({ React, productId, template = null, token }) {
  const res = await React.$fetch(React.$apis.overviewLevel3, {
    op_code: "get_parametric_data",
    product_id: Number(productId),
    function_id: 2,
    offset: 0,
    count: 100,
    ...(template ? { template } : ""),
  }, token);
  return res.data;
}
