import constants from "../../constants/constants.json";

export function sortCards({ data, sortOption }) {
  if (!data?.length) return [];
  switch (sortOption) {
    case constants.sortDesc:
      return data.sort((a, b) => b.product_name.localeCompare(a.product_name));
    case constants.favorites:
      return data;
    default:
      return data.sort((a, b) => a.product_name.localeCompare(b.product_name));
  }
}

export async function getOverviewLevel1({ React, token }) {
  const params = {
    op_code: "get_product_details",
    time_start: "2021-01-01 00:00:00",
    time_end: "2021-03-01 00:00:00",
    offset: 0,
    count: 9,
  }
  return await React.$fetch(React.$apis.overviewLevel1, params, token);
}
