export async function fetchSupplyDemandData({ React, productId, token }) {
  try {
    const res = await React.$fetch(React.$apis.summaryTable, {
      getSummaryTable: productId,
    }, token);
    if (res.status === 200) {
      if (res.data.query_type !== "table" || res.data.name !== "summary") {
        console.log("Error: can not get summary table");
        return false;
      }
      return res.data;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function supplayAndDemandGetProductById({ React, productId, token }) {
  try {
    const res = await React.$fetch(React.$apis.overviewLevel1, {
      op_code: "get_product_by_id",
      product_id: Number(productId),
      offset: 0,
      count: 100,
    }, token);
    return res.data;
  } catch (error) {
    console.log("Error: can not get product by id");
    console.log(error);
    return false;
  }
}

export function getProductId({ urlSearchParams }) {
  const urlParams = new URLSearchParams(urlSearchParams);
  const product_id = urlParams.get("product_id");
  return product_id;
}
